import React, { lazy, Suspense } from "react";
import { Box, CircularProgress } from "@mui/material";
import { createBrowserRouter } from "react-router-dom";
import Hexacore from "../Screens/Hexacore";
import LETInspectors from "../Screens/LetInspectors";
import Plat from "../Screens/Plat";

const Inland = lazy(() => import("../Screens/Inland"));
const SU = lazy(() => import("../Screens/SecurityUnion"));
const GO = lazy(() => import("../Screens/GOCoach"));
const Sports = lazy(() => import("../Screens/GOClient"));
const DoctorMeet = lazy(() => import("../Screens/DoctorMeet"));
const Error = lazy(() => import("../Screens/Error"));

const CustomLoader = ({ text }: { text: string }) => (
  <Box
    flexDirection={"column"}
    justifyContent={"center"}
    alignItems={"center"}
    flex={1}
  >
    <CircularProgress color="secondary" />
    <h3 style={{ textAlign: "center" }}>{text}</h3>
  </Box>
);

const errorElement = (
  <Suspense fallback={<CircularProgress color="secondary" />}>
    <Error />
  </Suspense>
);

const Routes = createBrowserRouter([
  {
    path: "/inland",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <Inland />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/securityunion",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <SU />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/gocoach",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <GO />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/gosports",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <Sports />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/doctormeet",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <DoctorMeet />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/hexacore",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <Hexacore />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/letinspectors",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <LETInspectors />
      </Suspense>
    ),
    errorElement,
  },
  {
    path: "/plat",
    element: (
      <Suspense fallback={<CustomLoader text="Cargando..." />}>
        <Plat />
      </Suspense>
    ),
    errorElement,
  },
]);

export default Routes;
